import { createRouter, createWebHistory } from "vue-router";
import login from "../components/login.vue";
import dataProvider from "../utils/dataProvider.js";
const dp = dataProvider();

const routes = [
  {
    path: "/login",
    name: "login",
    component: login,
  },
  {
    path: "/",
    name: "Main Page",
    component: () =>
      import("../components/calendarPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/basket",
    name: "Basket Page",
    component: () =>
      import( "../components/basketPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/listPage",
    name: "list Page",
    component: () =>
      import( "../components/listPage.vue"),
    meta: { requiresAuth: true },
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth) {
    //console.log(await dp.checkAuth(localStorage.getItem('JWT')))
    if ( !await dp.checkAuth()) {
      next({
        path: "/login",
      });
    }
    
    else next()
  } else next();
});

export default router;
