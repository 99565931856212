import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL || "http://localhost:5000"; //"http://192.168.10.52:5000"; //

const dataProvider = () => {
  //axios.defaults.headers.common['Authorization']='Bearer '+localStorage.getItem('JWT')
  (function () {
    let token = localStorage.getItem("JWT");

    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      axios.defaults.headers.common["Authorization"] = null;
      /*if setting null does not remove `Authorization` header then try     
          delete axios.defaults.headers.common['Authorization'];
        */
    }
  })();
  return {
    /////////------------------------------------------HANDOVER CONTRACT--------------------------
    uploadHandoverContract: async (pdf, event_id, type) => {
      console.log(pdf);
      let url = `${apiUrl}/uploadHandoverContract/${event_id}`;
      let formData = new FormData();
      formData.append("type", type);
      formData.append("pdf", pdf);

      //let data=await
      let data = await axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    createHandoverContract: async (user_uuid, event_id) => {
      let url = `${apiUrl}/createHandoverContract/${event_id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    //------------------------------------------------SEND MAIL---------------------------------------------------------------------
    sendMail: async (from, to, subject, text) => {
      let url = `${apiUrl}/sendMail`;
      try {
        let response = await axios.post(url, {
          from: from,
          to: to,
          subject: subject,
          text: text,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    //------------------------------------------------CHECK AUTH-------------------------------------------------------------------
    checkAuth: async () => {
      let url = `${apiUrl}/checkAuth`;
      try {
        let response = await axios.get(url);

        return response.data;
      } catch (error) {
        console.log(error);
        return undefined;
      }
    },
    //------------------------------------------ RESERVED_BATTERIES BATTERY---------------------------------------------------
    addBatteryToEvent: async (event_id, user_id, pilot_id, batteryLabId) => {
      let url = `${apiUrl}/reserved_batteries`;
      //let data=await
      let data = await axios
        .post(url, {
          event_id: event_id,
          user_uuid: user_id,
          pilot_id: pilot_id,
          battery_lab_id: batteryLabId,
        })
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    getReservedBatteryByLabId: async (battery_lab_id) => {
      let url = `${apiUrl}/reserved_batteries_lab_id/${battery_lab_id}`;
      //let data=await
      let data = await axios
        .get(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    getReservedBatteriesByEventId: async (event_id) => {
      let url = `${apiUrl}/reserved_batteries_event/${event_id}`;
      //let data=await
      let data = await axios
        .get(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    getReservedBatteriesByEventIdFree: async (event_id) => {
      let url = `${apiUrl}/reserved_batteries_event_free/${event_id}`;
      //let data=await
      let data = await axios
        .get(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    deleteReservedBatteriesByEventId: async (event_id) => {
      let url = `${apiUrl}/reserved_batteries_event/${event_id}`;
      //let data=await
      let data = await axios
        .delete(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    softDeleteReservedBatteriesByEventId: async (event_id) => {
      let url = `${apiUrl}/reserved_batteries_event_soft/${event_id}`;
      //let data=await
      let data = await axios
        .put(url, {
          free: true,
        })
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    deleteReservedBatteryById: async (id) => {
      let url = `${apiUrl}/reservedBatteryByID/${id}`;
      //let data=await
      let data = await axios
        .delete(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    editReservedBatteryById: async (
      id_old,
      event_id,
      pilot_id,
      user_uuid,
      battery_lab_id,
      free
    ) => {
      let url = `${apiUrl}/reserved_batteries/${id_old}`;
      //let data=await
      let data = await axios
        .put(url, {
          event_id: event_id,
          pilot_id: pilot_id,
          user_uuid: user_uuid,
          battery_lab_id: battery_lab_id,
          free: free,
        })
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },

    //------------------------------------------ RESERVED_DRONES---------------------------------------------------
    addDroneToEvent: async (event_id, user_id, pilot_id, droneLabId) => {
      let url = `${apiUrl}/reserved_drones`;
      //let data=await
      let data = await axios
        .post(url, {
          event_id: event_id,
          user_uuid: user_id,
          pilot_id: pilot_id,
          drone_lab_id: droneLabId,
        })
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    getReservedDronesByEventId: async (event_id) => {
      let url = `${apiUrl}/reserved_drones_event/${event_id}`;
      //let data=await
      let data = await axios
        .get(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    getReservedDronesByEventIdFree: async (event_id) => {
      let url = `${apiUrl}/reserved_drones_event_free/${event_id}`;
      //let data=await
      let data = await axios
        .get(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    getReservedDronesByLabId: async (lab_id) => {
      let url = `${apiUrl}/reserved_drones/${lab_id}`;
      //let data=await
      let data = await axios
        .get(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },

    getReservedDronesByPilotId: async (pilot_id) => {
      let url = `${apiUrl}/reserved_drones_pilot_id/${pilot_id}`;
      //let data=await
      let data = await axios
        .get(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    getReservedDronesByUserId: async (user_uuid) => {
      let url = `${apiUrl}/reserved_drones_user_id/${user_uuid}`;
      //let data=await
      let data = await axios
        .get(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    deleteReservedDroneById: async (id) => {
      let url = `${apiUrl}/deleteReservedDroneById/${id}`;
      //let data=await
      let data = await axios
        .delete(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    deleteReservedDroneByEventId: async (event_id) => {
      let url = `${apiUrl}/reserved_drones_event/${event_id}`;
      //let data=await
      let data = await axios
        .delete(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    softDeleteReservedDroneByEventId: async (event_id) => {
      let url = `${apiUrl}/reserved_drones_event_soft/${event_id}`;
      //let data=await
      let data = await axios
        .put(url, {
          free: true,
        })
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },

    editReservedDroneById: async (
      id_old,
      event_id,
      pilot_id,
      user_uuid,
      drone_lab_id,
      after_check,
      lab_check,
      field_check,
      free
    ) => {
      let url = `${apiUrl}/reserved_drones/${id_old}`;
      //let data=await
      let data = await axios
        .put(url, {
          event_id: event_id,
          pilot_id: pilot_id,
          user_uuid: user_uuid,
          drone_lab_id: drone_lab_id,
          after_check: after_check,
          lab_check: lab_check,
          field_check: field_check,
          free: free,
        })
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    //------------------------------------------ DRONE_BATTERY-----------------------------------------------------

    getDroneBatteryList: async () => {
      let url = `${apiUrl}/droneBattery`;
      //let data=await
      let data = await axios
        .get(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    getDroneBatteryById: async (lab_id) => {
      let url = `${apiUrl}/droneBattery/${lab_id}`;
      //let data=await
      let data = await axios
        .get(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    addDroneBattery: async (type, connector, required_batteries, lab_id) => {
      let url = `${apiUrl}/droneBattery`;
      //let data=await
      let data = await axios
        .post(url, {
          type: [type],
          connector: connector,
          required_batteries: required_batteries,
          lab_id: lab_id,
        })
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    deleteDroneBatteryById: async (item) => {
      let url = `${apiUrl}/droneBattery/${item.lab_id}`;
      //let data=await
      let data = await axios
        .delete(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    softDeleteDroneBatteryById: async (item) => {
      let url = `${apiUrl}/droneBattery_soft/${item.lab_id}`;
      //let data=await
      let data = await axios
        .put(url, {
          deleted: true,
        })
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    updateDroneBatteryById: async (
      type,
      connector,
      required_batteries,
      lab_id
    ) => {
      let url = `${apiUrl}/droneBattery/${lab_id}`;
      //let data=await
      let data = await axios
        .put(url, {
          type: [type],
          connector: connector,
          required_batteries: required_batteries,
          lab_id: lab_id,
        })
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },

    //------------------------------------------ DRONE_INSURANCE-----------------------------------------------------

    getDroneInsuranceList: async () => {
      let url = `${apiUrl}/droneInsurance`;
      //let data=await
      let data = await axios
        .get(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    getDroneInsuranceById: async (lab_id) => {
      let url = `${apiUrl}/droneInsurance/${lab_id}`;
      //let data=await
      let data = await axios
        .get(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    addDroneInsurance: async (start, end, number, lab_id) => {
      let url = `${apiUrl}/droneInsurance`;
      //let data=await
      let data = await axios
        .post(url, {
          start: start,
          end: end,
          number: number,
          lab_id: lab_id,
        })
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    deleteDroneInsuranceById: async (item) => {
      let url = `${apiUrl}/droneInsurance/${item.lab_id}`;
      //let data=await
      let data = await axios
        .delete(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    softDeleteDroneInsuranceById: async (item) => {
      let url = `${apiUrl}/droneInsurance_soft/${item.lab_id}`;
      //let data=await
      let data = await axios
        .put(url, {
          deleted: true,
        })
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    updateDroneInsuranceById: async (start, end, number, lab_id) => {
      let url = `${apiUrl}/droneInsurance/${lab_id}`;
      //let data=await
      let data = await axios
        .put(url, {
          start: start,
          end: end,
          number: number,
          lab_id: lab_id,
        })
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },

    ///-----------------------------------------DRONES------------------------------------------------------
    getDroneList: async () => {
      let url = `${apiUrl}/drones`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getDroneNotDeleted: async () => {
      let url = `${apiUrl}/dronesNotDeleted`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getDroneById: async (lab_id) => {
      let url = `${apiUrl}/drones/${lab_id}`;
      //let data=await
      let data = await axios
        .get(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    addDrone: async (
      name,
      type,
      serial_num,
      manufacturer,
      lab_id,
      comment,
      bought,
      registration_num,
      localization
    ) => {
      let url = `${apiUrl}/drones`;
      //let data=await
      let data = await axios
        .post(url, {
          name: name,
          type: type,
          serial_num: serial_num,
          manufacturer: manufacturer,
          lab_id: lab_id,
          comment: comment,
          bought: bought,
          registration_num: registration_num,
          localization: localization,
        })
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    deleteDroneById: async (item) => {
      let url = `${apiUrl}/drones/${item.lab_id}`;
      //let data=await
      let data = await axios
        .delete(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    softDeleteDroneById: async (item) => {
      let url = `${apiUrl}/drones_soft/${item.lab_id}`;
      //let data=await
      let data = await axios
        .put(url, {
          deleted: true,
        })
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    editDrone: async (
      name,
      type,
      serial_num,
      manufacturer,
      lab_id,
      comment,
      bought,
      registration_num,
      localization,
      old_lab_id
    ) => {
      let url = `${apiUrl}/drones/${old_lab_id}`;
      //let data=await
      let data = await axios
        .put(url, {
          name: name,
          type: type,
          serial_num: serial_num,
          manufacturer: manufacturer,
          lab_id: lab_id,
          comment: comment,
          bought: bought,
          registration_num: registration_num,
          localization: localization,
        })
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },

    ///-----------------------------------------DRONE_IMAGES------------------------------------------------------
    getDroneImages: async () => {
      let url = `${apiUrl}/drone_images`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getDroneImagesByLabId: async (lab_id) => {
      let url = `${apiUrl}/droneImagesByLabId/${lab_id}`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    downloadDroneImagesByUuid: async (uuid) => {
      let url = `${apiUrl}/drone_images/${uuid}`;
      //let data = await axios
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    uploadDroneImages: async (old_name, lab_id, image) => {
      let url = `${apiUrl}/drone_images`;
      let formData = new FormData();
      formData.append("old_name", old_name);
      formData.append("lab_id", lab_id);
      formData.append("bucket", "droneimages");
      formData.append("image", image);
      //let data=await
      let data = await axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    deleteDroneImagesByLabId: async (item) => {
      let url = `${apiUrl}/drone_images/${item.lab_id}`;
      //let data=await

      let data = await axios
        .delete(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    softDeleteDroneImagesByLabId: async (item) => {
      let url = `${apiUrl}/drone_images_soft/${item.lab_id}`;
      //let data=await

      let data = await axios
        .put(url, {
          deleted: true,
        })
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },

    ///-----------------------------------------DRONE_IMAGES_AFTERFLIGHT------------------------------------------------------
    getDroneImagesAfterFlight: async () => {
      let url = `${apiUrl}/drone_images_afterflight`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getAfterImagesByEventAndLabId: async (lab_id, event_id) => {
      let url = `${apiUrl}/droneImagesByLabIdAfterFlight/${event_id}/${lab_id}`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    downloadDroneImagesByLabIdAfterFlight: async (uuid) => {
      let url = `${apiUrl}/drone_images_afterflight/${uuid}`;
      //let data=await
      let data = await axios
        .get(url)
        .then(function (response) {
          console.log(response);
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    uploadDroneImagesAfterFlight: async (old_name, lab_id, image, event_id) => {
      let url = `${apiUrl}/drone_images_afterflight`;
      let formData = new FormData();
      formData.append("old_name", old_name);
      formData.append("lab_id", lab_id);
      formData.append("bucket", "droneimagesafterflight");
      formData.append("event_id", event_id);
      formData.append("image", image);

      //let data=await
      let data = await axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    deleteDroneImagesByLabIdAfterFlight: async (item) => {
      let url = `${apiUrl}/drone_images_afterflight/${item.lab_id}`;
      //let data=await
      let data = await axios
        .delete(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    ///-----------------------------------------DRONE_PDF------------------------------------------------------
    getDronePdf: async () => {
      let url = `${apiUrl}/drone_pdf`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getDronePdfLabId: async (event_id, lab_id) => {
      let url = `${apiUrl}/drone_pdfByLabId/${event_id}/${lab_id}`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    downloadDronePdfByUuid: async (uuid) => {
      let url = `${apiUrl}/drone_pdf/${uuid}`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    uploadDronePdf: async (old_name, lab_id, pdf, event_id) => {
      console.log(pdf);
      let url = `${apiUrl}/drone_pdf`;
      let formData = new FormData();
      formData.append("old_name", old_name);
      formData.append("lab_id", lab_id);
      formData.append("bucket", "dronepdf");
      formData.append("event_id", event_id);

      formData.append("pdf", pdf);

      //let data=await
      let data = await axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },

    uploadSampleDronePdf: async (old_name, pdf) => {
      let url = `${apiUrl}/drone_pdfSample`;
      let formData = new FormData();
      formData.append("old_name", old_name);
      formData.append("bucket", "dronepdf");
      formData.append("pdf", pdf);

      //let data=await
      let data = await axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },

    deleteDronePdfByLabId: async (item) => {
      let url = `${apiUrl}/drone_pdf/${item.lab_id}`;
      //let data=await
      let data = await axios
        .delete(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },

    ///-----------------------------------------SAMPLE------------------------------------------------------

    downloadUserGuideByUuid: async (uuid) => {
      let url = `${apiUrl}/sample/${uuid}`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    downloadDocxByUuid: async (uuid) => {
      let url = `${apiUrl}/sampleDocx/${uuid}`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    uploadSample: async (old_name, pdf) => {
      let url = `${apiUrl}/sample`;
      let formData = new FormData();
      formData.append("old_name", old_name);
      formData.append("bucket", "sample");
      formData.append("pdf", pdf);

      //let data=await
      let data = await axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },

    ///-----------------------------------------DRONE_LOG------------------------------------------------------
    getDroneLog: async () => {
      let url = `${apiUrl}/drone_log`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getDroneLogsLabId: async (event_id, lab_id) => {
      let url = `${apiUrl}/drone_logByLabId/${event_id}/${lab_id}`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    downloadDroneLogByUuid: async (uuid) => {
      let url = `${apiUrl}/drone_log/${uuid}`;
      //let data=await
      let data = await axios
        .get(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    uploadDroneLog: async (old_name, lab_id, log, event_id) => {
      let url = `${apiUrl}/drone_log`;
      let formData = new FormData();
      formData.append("old_name", old_name);
      formData.append("lab_id", lab_id);
      formData.append("bucket", "dronelog");
      formData.append("event_id", event_id);

      formData.append("log", log);

      //let data=await
      let data = await axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    deleteDroneLogByLabId: async (item) => {
      let url = `${apiUrl}/drone_log/${item.lab_id}`;
      //let data=await
      let data = await axios
        .delete(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },

    //------------------------------------------ BATTERY-----------------------------------------------------
    getBatteryList: async () => {
      let url = `${apiUrl}/batterys`;
      //let data=await
      let data = await axios
        .get(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    getBatteryNotDeleted: async () => {
      let url = `${apiUrl}/batterysNotDeleted`;
      //let data=await
      let data = await axios
        .get(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    getBatteryById: async (lab_id) => {
      let url = `${apiUrl}/batterys/${lab_id}`;
      //let data=await
      let data = await axios
        .get(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    addBattery: async (
      cell_num,
      capacity,
      manufacturer,
      type,
      lab_id,
      connector,
      name,
      technology,
      localization,
      c_value
    ) => {
      let url = `${apiUrl}/batterys`;
      //let data=await
      let data = await axios
        .post(url, {
          cell_num: cell_num,
          capacity: capacity,
          manufacturer: manufacturer,
          type: type,
          lab_id: lab_id,
          connector: connector,
          name: name,
          technology: technology,
          localization: localization,
          c_value: c_value,
        })
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    deleteBatteryById: async (item) => {
      let url = `${apiUrl}/batterys/${item.lab_id}`;
      //let data=await
      let data = await axios
        .delete(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    softDeleteBatteryById: async (item) => {
      let url = `${apiUrl}/batterys_soft/${item.lab_id}`;
      //let data=await
      let data = await axios
        .put(url, {
          deleted: true,
        })
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    editBattery: async (
      cell_num,
      capacity,
      manufacturer,
      type,
      lab_id,
      connector,
      name,
      technology,
      localization,
      c_value,
      old_lab_id
    ) => {
      let url = `${apiUrl}/batterys/${old_lab_id}`;
      //let data=await
      let data = await axios
        .put(url, {
          cell_num: cell_num,
          capacity: capacity,
          manufacturer: manufacturer,
          type: type,
          lab_id: lab_id,
          connector: connector,
          name: name,
          technology: technology,
          localization: localization,
          c_value: c_value,
        })
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    ///-----------------------------------------BATTERY_IMAGES------------------------------------------------------
    getBatteryImages: async () => {
      let url = `${apiUrl}/battery_images`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getBatteryImagesByLabId: async (lab_id) => {
      let url = `${apiUrl}/batteryImagesByLabId/${lab_id}`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    downloadBatteryImagesByLabId: async (uuid) => {
      let url = `${apiUrl}/battery_images/${uuid}`;
      //let data=await
      let data = await axios
        .get(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    uploadBatteryImages: async (old_name, lab_id, image) => {
      let url = `${apiUrl}/battery_images`;
      let formData = new FormData();
      formData.append("old_name", old_name);
      formData.append("lab_id", lab_id);
      formData.append("bucket", "batteryimages");
      formData.append("image", image);
      //let data=await
      let data = await axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    deleteBatteryImagesByLabId: async (item) => {
      let url = `${apiUrl}/battery_images/${item.lab_id}`;
      //let data=await
      let data = await axios
        .delete(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    softDeleteBatteryImagesByLabId: async (item) => {
      let url = `${apiUrl}/battery_images_soft/${item.lab_id}`;
      let data = await axios
        .put(url, {
          deleted: true,
        })
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          return error;
        });
      return data;
    },

    //------------------------------------------ PILOT----------------------------------------------------

    //getOne: (resource, params) => {},
    getPilotList: async () => {
      let url = `${apiUrl}/pilot`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getPilotListWithUserData: async () => {
      let url = `${apiUrl}/pilotWithUser`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getPilotNotDeleted: async () => {
      let url = `${apiUrl}/pilotNotDeleted`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getPilotById: async (id) => {
      let url = `${apiUrl}/pilot/${id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getPilotByUserUuid: async (user_uuid) => {
      let url = `${apiUrl}/pilot/uuid/${user_uuid}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    addPilot: async (licence_id, licence_level, user_uuid) => {
      let url = `${apiUrl}/pilot`;
      //let data=await
      let data = await axios
        .post(url, {
          licence_id: licence_id,
          licence_level: licence_level,
          user_uuid: user_uuid,
        })
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    editPilotByUserUuid: async (licenceId, licenceLevel, user_uuid) => {
      let url = `${apiUrl}/pilot/${user_uuid}`;
      //let data=await
      let data = await axios
        .put(url, {
          licence_id: licenceId,
          licence_level: licenceLevel,
        })
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    deletePilotById: async (item) => {
      let url = `${apiUrl}/pilot/${item.licence_id}`;
      //let data=await
      let data = await axios
        .delete(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    softDeletePilotById: async (item) => {
      let url = `${apiUrl}/pilot_soft/${item.licence_id}`;
      //let data=await
      let data = await axios
        .put(url, {
          deleted: true,
        })
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },

    ///-----------------------------------------PILOT_IMAGES------------------------------------------------------
    getPilotImages: async () => {
      let url = `${apiUrl}/pilot_images`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getPilotImagesByLicenceId: async (licence_id) => {
      let url = `${apiUrl}/pilot_imagesByLicenceId/${licence_id}`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    downloadPilotImagesByUuid: async (uuid) => {
      let url = `${apiUrl}/pilot_images/${uuid}`;

      //let data = await axios
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    uploadPilotImages: async (old_name, licence_id, image) => {
      let url = `${apiUrl}/pilot_images`;
      let formData = new FormData();
      formData.append("old_name", old_name);
      formData.append("licence_id", licence_id);
      formData.append("bucket", "pilotimages");
      formData.append("image", image);
      //let data=await
      let data = await axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    deletePilotImagesByLicenceId: async (item) => {
      let url = `${apiUrl}/pilot_images/${item.licence_id}`;
      //let data=await
      let data = await axios
        .delete(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    softDeletePilotImagesByLicenceId: async (item) => {
      let url = `${apiUrl}/pilot_images_soft/${item.licence_id}`;
      //let data=await
      let data = await axios
        .put(url, {
          deleted: true,
        })
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    //------------------------------------------ EVENTS----------------------------------------------------
    getMyEvents: async (user_uuid) => {
      let url = `${apiUrl}/eventByUserUuid`;
      //let data=await
      let data = await axios
        .post(url, {
          user_uuid: user_uuid,
        })
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    getEventsByUserUuidDateNotDelete: async (user_uuid) => {
      let url = `${apiUrl}/eventByUserUuidNotDelete`;
      //let data=await
      let data = await axios
        .post(url, {
          user_uuid: user_uuid,
        })
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    getEventByDate: async (dateStart, dateEnd) => {
      let url = `${apiUrl}/eventByDate`;
      //let data=await
      let data = await axios
        .post(url, {
          date_start: dateStart,
          date_end: dateEnd,
        })
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },

    getEventList: async () => {
      let url = `${apiUrl}/event`;
      //let data=await
      let data = await axios
        .get(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    getEventsNotDeleted: async () => {
      let url = `${apiUrl}/eventNotDeleted`;
      //let data=await
      let data = await axios
        .get(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    getRequestList: async () => {
      let url = `${apiUrl}/eventRequest`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getEventById: async (id) => {
      let url = `${apiUrl}/event/${id}`;
      //let data=await
      let data = await axios
        .get(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data[0];
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },

    addEvent: async (
      eventName,
      dateStart,
      dateEnd,
      requestStatus,
      reason,
      neededPilots
    ) => {
      let url = `${apiUrl}/event`;
      //let data=await
      let data = await axios
        .post(url, {
          event_name: eventName,
          date_start: dateStart,
          date_end: dateEnd,
          request_status: requestStatus,
          reason: reason,
          needed_pilots: neededPilots,
        })
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },

    deleteEventById: async (id) => {
      let url = `${apiUrl}/event/${id}`;
      try {
        let response = await axios.delete(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    softDeleteEventById: async (id) => {
      let url = `${apiUrl}/event_soft/${id}`;
      try {
        let response = await axios.put(url, { deleted: true });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    editEvent: async (
      event_id,
      event_name,
      date_start,
      date_end,
      request_status,
      reason
    ) => {
      let url = `${apiUrl}/event/${event_id}`;
      //let data=await
      let data = await axios
        .put(url, {
          event_name: event_name,
          date_start: date_start,
          date_end: date_end,
          request_status: request_status,
          reason: reason,
        })
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    //----------------------------------------USER-------------------------------------------------------------
    async requestNewPw(email, newpw) {
      let url = `${apiUrl}/requestNewPw/${email}`;
      //let data=await
      let data = await axios
        .put(url, { newpw: newpw })
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    getUserList: async () => {
      let url = `${apiUrl}/user`;
      //let data=await
      let data = await axios
        .get(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    getUserListNotDeleted: async () => {
      let url = `${apiUrl}/user_notDeleted`;
      //let data=await
      let data = await axios
        .get(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },

    addUser: async (
      password,
      email,
      phoneNum,
      role,
      lastName,
      firstName,
      organization
    ) => {
      let url = `${apiUrl}/user`;
      //let data=await
      let data = await axios
        .post(url, {
          password: password,
          email: email,
          phone_num: phoneNum,
          role: role,
          last_name: lastName,
          first_name: firstName,
          organization: organization,
        })
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    getUserByEmail: async (email) => {
      let url = `${apiUrl}/user/${email}`;
      //let data=await
      let data = await axios
        .get(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    getUserByUuid: async (uuid) => {
      let url = `${apiUrl}/userByUuid/${uuid}`;
      //let data=await
      let data = await axios
        .get(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    deleteUserByEmail: async (item) => {
      let url = `${apiUrl}/user/${item.email}`;
      //let data=await
      let data = await axios
        .delete(url)
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    softDeleteUserByEmail: async (item) => {
      let url = `${apiUrl}/user_soft/${item.email}`;
      //let data=await
      let data = await axios
        .put(url, {
          deleted: true,
        })
        .then(function (response) {
          //handle success
          //console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    editUserByEmail: async (
      password,
      email,
      phoneNum,
      role,
      lastName,
      firstName,
      organization
    ) => {
      let url = `${apiUrl}/user/${email}`;
      //let data=await
      let data = await axios
        .put(url, {
          password: password,
          email: email,
          phone_num: phoneNum,
          role: role,
          last_name: lastName,
          first_name: firstName,
          organization: organization,
        })
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    login: async (email, password) => {
      let url = `${apiUrl}/user/login`;
      let data = await axios
        .post(url, {
          email: email,
          password: password,
        })
        .then(function (response) {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + response.data.jwt;
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
  };
};
export default dataProvider;
