<template>
  <div class="loginPage">
    <div class="backgroundImage"></div>
    <div class="backgroundColor"></div>
    <div class="logoPlaceBackground">
      <div class="logoPlace1">
        <img class="logo1" src="../assets/DFK_DDC-02.png" /><img />
      </div>
    </div>

    <!-- ---------------------FORGOT PW MODAL---------------------- -->
    <div
      class="modal fade"
      id="forgotPwModal"
      role="dialog"
      aria-labelledby="forgotPwModal"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content userModalContent">
          <div class="modal-header">
            <h5 id="createBatteryTitle">Request a new password</h5>
            <h5 class="modal-title" id="forgotPwModalLabel"></h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
            ></button>
          </div>

          <div class="modal-body">
            <label class="labelText" for="email">Email:&nbsp;</label>
            <input
              v-model="newPwRequest"
              type="email"
              class="new-user-input form-control inputBox2"
              name="email"
              id="forgotPwEmail"
              placeholder="email@address.com"
              @keydown="atKeyDown"
            />
            <br />
            <br />
            <button
              id="sendPwRequestButton"
              type="button"
              class="btn btn-success"
              data-bs-dismiss="modal"
              @click="sendPwRequest()"
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- ---------------------CREATE NEW USER MODAL---------------------- -->
    <div
      class="modal fade"
      id="createUserModal"
      role="dialog"
      aria-labelledby="createUserModal"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content userModalContent">
          <div class="modal-header">
            <h5 id="createBatteryTitle">&nbsp;Create new User</h5>
            <h5 class="modal-title" id="createUserModalLabel"></h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
            ></button>
          </div>

          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-auto">
                Please fill the form if you want to register a new User
                <br />
                <br />
                <div class="create">
                  <label class="labelText" for="firstName"
                    >First Name:&nbsp;</label
                  >
                  <input
                    v-model="createFirstName"
                    type="text"
                    name="firstName"
                    id="firstNameInput"
                    class="new-user-input form-control inputBox2"
                    placeholder="First Name"
                    @keydown="atKeyDown"
                    @keyup="atKeyup"
                  />
                  <br />
                  <br />
                  <label class="labelText" for="lastName"
                    >Last Name:&nbsp;</label
                  >
                  <input
                    v-model="createLastName"
                    type="text"
                    name="lastName"
                    id="lastNameInput"
                    class="new-user-input form-control inputBox2"
                    placeholder="Last Name"
                    @keydown="atKeyup"
                  />
                  <br />
                  <br />
                  <label class="labelText" for="email">Email:&nbsp;</label>
                  <input
                    v-model="createEmail"
                    type="email"
                    class="new-user-input form-control inputBox2"
                    name="email"
                    id="createEmail"
                    placeholder="email@address.com"
                    @keydown="atKeyDown"
                  />
                  <br />
                  <br />
                  <label class="labelText" for="password"
                    >Password:&nbsp;

                    <a
                      href="#"
                      title="Password must contain: 
  -at least 8 characters 
  -at least 1 number
  -at least 1 lowercase character
  -at least 1 uppercase character"
                      data-bs-toggle="popover"
                      data-bs-trigger="hover"
                      data-bs-content="Popover text"
                      ><i class="fa-solid fa-circle-info"></i
                    ></a>
                  </label>
                  <input
                    v-model="createPassword"
                    type="password"
                    name="password"
                    id="createPasswordInput"
                    class="new-user-input form-control inputBox2"
                    placeholder="Password"
                    @keydown="atKeyDown"
                  /><button
                    @click="showPassword('password')"
                    class="eyeButtonLogin"
                    id="showPasswordButtonLogin"
                  >
                    <i class="far fa-eye" id="pwEyeLogin"></i>
                    <i class="far fa-eye-slash" id="pwSlashedEyeLogin"></i>
                  </button>
                  <br />
                  <br />
                  <label class="labelText" for="password"
                    >Password again:&nbsp;</label
                  >
                  <input
                    v-model="createPasswordAgain"
                    type="password"
                    name="passwordAgain"
                    id="passwordAgainInput"
                    class="new-user-input form-control inputBox2"
                    placeholder="Password"
                    @keydown="atKeyDown"
                  />
                  <br />
                  <br /><button
                    @click="showPassword('passwordAgain')"
                    class="eyeButtonLogin"
                    id="showPasswordAgainButtonLogin"
                  >
                    <i class="far fa-eye" id="pwAgainEyeLogin"></i>
                    <i class="far fa-eye-slash" id="pwAgainSlashedEyeLogin"></i>
                  </button>

                  <label class="labelText" for="phoneNum"
                    >Phone number:&nbsp;</label
                  >&nbsp;&nbsp;&nbsp;
                  <input
                    v-model="createPhoneNum"
                    type="text"
                    name="phoneNum"
                    id="createPhoneNumInput"
                    class="new-user-input form-control phoneInputField"
                    placeholder="1234567"
                    @keydown="atKeyDown"
                    maxlength="7"
                  />
                  <input
                    list="selected"
                    name="userSearch"
                    id="createPhoneNumSelectInput"
                    class="new-user-input form-control phoneInputField"
                    autocomplete="off"
                    placeholder="96"
                    maxlength="2"
                    v-model="this.selectedNum"
                  />

                  <datalist id="selected">
                    <option>20</option>
                    <option>30</option>
                    <option>31</option>
                    <option>50</option>
                    <option>70</option>
                    <option>96</option>
                  </datalist>
                  <input
                    id="createPhoneInput"
                    class="new-user-input form-control phoneInputField"
                    type="text"
                    :value="this.plusPhone"
                    disabled
                  />&nbsp;

                  <br />
                  <br />

                  <label class="labelText" for="type">Organization:&nbsp;</label
                  >&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <select
                    v-model="this.selectedOrganization"
                    class="techDropdownLogin"
                    id="organizationInput"
                  >
                    <option hidden value="undefined">Organization</option>
                    <option>DDC</option>
                    <option>JKK</option>
                    <option>AHJK</option>
                    <option>AK</option>
                    <option>EEKK</option>
                    <option>ESK</option>
                    <option>GIVK</option>
                    <option>KGK</option>
                    <option>MEK</option>
                    <option>MUK</option>
                    <option>Budapesti központ</option>
                    <option>ZalaZONE</option>
                    <option>None above</option>
                  </select>
                  <br v-show="this.selectedOrganization == 'None above'" />
                  <br v-show="this.selectedOrganization == 'None above'" />
                  <input
                    v-show="this.selectedOrganization == 'None above'"
                    v-model="createOrganization"
                    type="text"
                    name="organization"
                    class="new-user-input form-control"
                    placeholder="Organization"
                    @keydown="atKeyDown"
                  />
                  <br />
                  <br />

                  <div class="form-check checkUser">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="termsConditions"
                      name="option1"
                    />

                    <label class="form-check-label"
                      >You agree to the
                      <button
                        type="button"
                        class="btn btn-primary"
                        id="termsButton2"
                        data-bs-toggle="modal"
                        data-bs-target="#termsLoginModal"
                      >
                        Terms and Privacy Policy
                      </button></label
                    >
                  </div>
                </div>
              </div>
              <div class="modal-footer modalFooter">
                <div class="footerButtons">
                  <button
                    type="button"
                    id="sureUserCreateLogin"
                    class="btn btn-success"
                    @click="createNewUser()"
                  >
                    <i class="fa-solid fa-user-plus"></i> Create
                  </button>
                  <button
                    type="button"
                    @click="cancelFunct()"
                    data-bs-dismiss="modal"
                    class="btn btn-danger"
                    id="cancelButtonLogin"
                  >
                    <i class="fa-solid fa-xmark"></i> Cancel
                  </button>
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    class="btn btn-danger"
                    id="closeModal"
                    style="display: none"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ---------------------CREATE NEW USER MODAL END---------------------- -->
    <!-- ---------------TERMS MODAL------------------ -->

    <div class="modal" id="termsLoginModal">
      <div class="modal-dialog modal-lg largeModal" role="document">
        <div class="modal-content termsModalContent">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Terms and Privacy Policy</h4>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
            ></button>
          </div>

          <!-- Modal body -->
          <div class="modal-body">
            1 Általános Felhasználási Feltételek (ÁFF) <br /><br />1. A
            SZOLGÁLTATÓ<br /><br />1.1. Ön, mint Felhasználó (jelen
            dokumentumban: Ön, Felhasználó) a Felhasználó és az Egyetem közötti
            együttműködés feltételeit olvassa.<br /><br />
            1.2.Kérjük, az oldal használata előtt figyelmesen olvassa el jelen
            dokumentumot. A weboldalunkhoz való csatlakozás, az azon történő
            böngészés, vagy a weboldal bármely alkalmazásának, vagy
            szolgáltatásának használata során jelen Felhasználási Feltételek
            rendelkezései irányadóak, amelyeket Ön a weboldal használatával
            elfogad (Megállapodás). Jelen Megállapodás Ön és az Egyetem között
            jött létre. Jelen Megállapodás nem minősül írásba foglalt
            szerződésnek. A szerződés kizárólag magyar nyelven köthető meg.<br /><br />
            1.3. Jelen Felhasználási Feltételek 2022.09.12.napján léptek
            hatályba. Az Egyetem fenntartja magának a jogot a Felhasználási
            Feltételek egyoldalú módosítására, előzetes értesités nélkül. Ezért
            kérjük, látogassa ezt az oldalt időnként és rendszeresen ellenőrizze
            a feltételeket. Az esetleges módosítás a Weboldalon való
            megjelenéssel egyidejűleg lép hatályba. A módosított Felhasználási
            Feltételeket az ismételt használattal a felhasználó tudomásul
            veszi.<br /><br />
            1.4. A Felhasználó jogai: Az általános felhasználási feltételek csak
            a Felhasználó jelen Megállapodással érintett jogait érintik.
            <br /><br />
            2. Sazbályok:<br /><br />
            2.1 Tilos az oldal szolgáltatásainak kereskedelmi célú
            továbbközvetítése. Az oldal számítástechnikai visszafejtése, vagy
            feltörése szigorúan tilos.<br /><br />
            2.2. A hozzáférés megtagadása: Az Egyetem fenntartja a jogot a
            Honlap, a Szolgáltatások (vagy azok egy része) megtagadására.
            <br /><br />
            2.3. Eszközök: A Szolgáltatás és a Honlap használatának
            lebonyolításának biztosítása nem jelenti azt,hogy az Egyetem az
            ehhez szükséges technikai feltételeket - telefon, számítógépes
            hardver beszerzése és karbantartása - is biztosítaná. A Honlap, vagy
            Szolgáltatások igénybevételéhez internet hozzáférésre van szükség. A
            Szolgáltatás igénybevétele során keletkező telefonköltségekért,
            telekommunikációs-, és egyéb használati díjakért az Egyetem
            felelősséget nem vállal. <br /><br />
            3. REGISZTRÁCIÓ ÉS FELHASZNÁLÓI FIÓKOK <br /><br />
            3.1. Miért érdemes regisztrálni? A Honlap összes funkcióinak
            használatához, illetőleg a Szolgáltatások igénybevételéhez
            Regisztráció szükséges. <br /><br />
            3.2. Hogy regisztráljunk: A Regisztrációhoz meg kell adnia a nevét,
            e-mail címét, telefonszámát, egy jelszót és néhány egyéb személyes
            információt. <br /><br />
            3.3. Jelszavak: A Felhasználó köteles jelszavát megőrizni, és
            azonnal értesíteni az adminisztrátor joggal rendelkező kollégát,
            hogyha jelszavát harmadik személy tudomására hozta, vagy bármely más
            ehhez kapcsolódó titkosinformáció idegenek számára hozzáférhetővé
            vált, esetleg elfelejtette azt. A jelszó titokban tartásának
            figyelmenkívül hagyásából származó minden felelősség a Felhasználót
            terheli. <br /><br />
            3.4. Érvényes e-mail cím: Minden Felhasználói Fiókhoz történő
            Regisztráció csak érvényes e-mail címmel történhet. Érvénytelen
            Felhasználói e-mail cím gyanúja esetén az Egyetem kérheti a fiók
            újbóli érvényesítését. <br /><br />
            4. A FELHASZNÁLÓ KÖTELEZETTSÉGEI <br /><br />
            4.1. Valós adatszolgáltatás: A Felhasználót terheli a felelősség,
            hogy a Regisztráció során megadott és a felhasználói fiókrészeként
            jelen megállapodás fennállása alatt kezelt adatok valósak, teljesek
            és helytállóak és egyben a Felhasználó kötelezettsége, hogy bármely
            adat változását a felhasználói fiók haladéktalan frissítésével
            jelezze. <br /><br />
            4.2. Tilos az oldalra tisztességtelen, vagy ízléstelen tartalmat
            ,olyan trágár, sértő, obszcén, fenyegető, istenkáromló, rágalmazó
            szöveges tartalmat feltölteni, amely alkalmas bármely személy, vagy
            hivatalos szerv megsértésére, sérti a közbizalmat, a szerzői-,
            személyiségi jogokat, beavatkozik bárki köz-, vagy magánszférájába.
            Tilos rendeltetés ellenesen, vagy az Egyetem szándéka szerinti
            rendeltetéssel ellentétes módon használni <br /><br />
            5. A SZOLGÁLTATÁSOK ÉS A HONLAP HASZNÁLATÁNAK SZABÁLYAI <br /><br />
            5.1. Az Egyetem minden szükséges intézkedést megtesz, hogy az
            esetlegesen felmerülő hibákat, vagy hiányosságokat annak észlelését
            követően a legrövidebb időn belül kijavítson. Ugyanakkor az Egyetem
            nem garantálja a Szolgáltatás, vagy a Honlap hibától mentes
            működését és nem vállal felelősséget az ebből fakadó hibákért,
            hiányosságokért. Hiba észlelése esetén várjuk az azonnali
            értesítést. <br /><br />
            5.2. Az Egyetem a webes felület jellegéből fakadóan nem garantálja,
            hogy a Szolgáltatás illetve Honalap használata ne szakadjon meg és
            hogy a Szolgáltatás keretében, illetve a Honlapon küldött
            információk minden esetben pontosan, használhatóan, megfelelő időben
            megérkeznek. <br /><br />
            5.3. Az Egyetem nem garantálja a rendszer, vagy Honlap vírusoktól,
            vagy egyéb káros technikai hatásoktól való mentességét. <br /><br />
          </div>

          <!-- Modal footer -->
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!----------------- END------------ -->
    <div class="loginTitle">Rent a drone</div>
    <div class="login">
      <div class="loginContent">
        <input
          class="inputBoxLogin"
          v-model="useremail"
          type="email"
          name="useremail"
          placeholder=" Username"
          @keydown="isEnter()"
        />
        <br />

        <div class="passwordLine">
          <input
            class="inputBoxLogin"
            id="passwordInput"
            placeholder=" Password"
            v-model="password"
            type="password"
            @keydown="isEnter()"
          />
          <button
            @click="showPassword('loginPassword')"
            class="eyeButton"
            id="loginPasswordButtonLogin"
          >
            <i class="far fa-eye" id="eyeIconLogin"></i>
            <i class="far fa-eye-slash" id="slashedEyeLogin"></i>
          </button>
        </div>
        <br />
        <br />
        <button
          class="btn btn-secondary"
          type="submit"
          @click="login()"
          id="loginButton"
        >
          Login
        </button>
        <br />
      </div>
      <button
        class="btn"
        type="button"
        id="newUserButton"
        data-bs-toggle="modal"
        data-bs-target="#createUserModal"
      >
        <i class="fa-solid fa-user-plus"></i> Create new user
      </button>
      <br />
      <button
        class="btn"
        type="button"
        id="forgotPwButton"
        data-bs-toggle="modal"
        data-bs-target="#forgotPwModal"
      >
        <i class="fa-solid fa-key"></i> Forgot Password
      </button>
    </div>
  </div>
</template>

<script>
import App from "@/App.vue";
import dataProvider from "../utils/dataProvider.js";
const dp = dataProvider();
export default {
  data() {
    return {
      newPwRequest: undefined,
      selectedOrganization: undefined,
      plusPhone: "+36",
      selectedNum: undefined,

      useremail: null,
      password: null,
      error: null,
      deviceCount: 0,
      domainCount: 0,
      userCount: 0,

      createUsername: undefined,
      createPassword: undefined,
      createPasswordAgain: undefined,
      createEmail: undefined,
      createPhoneNum: undefined,
      createFirstName: undefined,
      createLastName: undefined,
      createOrganization: undefined,
      role: "user",

      loginShow: true,
    };
  },

  methods: {
    cancelFunct() {
      this.createUsername = undefined;
      this.createPassword = undefined;
      this.createPasswordAgain = undefined;
      this.createEmail = undefined;
      this.createPhoneNum = undefined;
      this.createFirstName = undefined;
      this.createLastName = undefined;
      this.createOrganization = undefined;
      this.selectedOrganization = undefined;
    },
    newPW() {
      let length = 8,
        charset =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
      for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return retVal;
    },
    async sendPwRequest() {
      let email = this.newPwRequest;
      console.log(email);
      try {
        let user = await dp.getUserByEmail(email);
        console.log(user);
        if (user) {
          let newPassowrd = this.newPW();
          await dp.requestNewPw(email,newPassowrd)
          let from = "dron@tilb.sze.hu";
          let to=email
          let subject =
        "Új jelszó igénylés Drónfoglaló rendszerhez";
        let text =
        `Tisztelt Felhasználó! \nÖn új jelszót igényelet a Drónfolglaó rendszerhez. \nAz új jelszava: ${newPassowrd} \nBelépést követően a "user edit" menüpont alatt tudja megváltoztatni a jelszavát.
        `
          await dp.sendMail(from,to,subject,text)
        } else {
          alert("User with this email adress can not be found");
        }
      } catch (error) {
        console.log(error);
      }
    },
    //----------------------------------CREATE USER---------------------------
    async createNewUser() {
      const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };
      let validatePassword = (password) => {
        return String(password).match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/);
      };
      let validatePhoneNum = (phoneNum) => {
        return String(phoneNum).match(/^([0-9]{6,7})$/);
      };
      let validateSelectedPhoneNum = (phoneNum) => {
        return String(phoneNum).match(/^([0-9]{2})$/);
      };
      let firstNameBool = false;
      let lastNameBool = false;
      let createEmailBool = false;
      let createPasswordBool = false;
      let createPasswordAgainBool = false;
      let createPhoneNumBool = false;
      let createPhoneNumSelectBool = false;
      let createOrgBool = false;
      let termsConditionsBool = false;

      if (document.getElementById("firstNameInput").value) {
        firstNameBool = true;
        document.getElementById("firstNameInput").style.border =
          "medium solid #3f9140";
      } else {
        firstNameBool = false;
        document.getElementById("firstNameInput").style.border =
          "medium solid #c9021d";
      }
      if (document.getElementById("lastNameInput").value) {
        lastNameBool = true;
        document.getElementById("lastNameInput").style.border =
          "medium solid #3f9140";
      } else {
        lastNameBool = false;
        document.getElementById("lastNameInput").style.border =
          "medium solid #c9021d";
      }

      if (validateEmail(document.getElementById("createEmail").value)) {
        createEmailBool = true;
        document.getElementById("createEmail").style.border =
          "medium solid #3f9140";
      } else {
        createEmailBool = false;
        document.getElementById("createEmail").style.border =
          "medium solid #c9021d";
      }
      if (
        validatePassword(document.getElementById("createPasswordInput").value)
      ) {
        createPasswordBool = true;
        document.getElementById("createPasswordInput").style.border =
          "medium solid #3f9140";
      } else {
        createPasswordBool = false;
        document.getElementById("createPasswordInput").style.border =
          "medium solid #c9021d";
        alert(
          "Password must contain: \n -at least 8 characters \n -at least 1 number \n -at least 1 lowercase character\n -at least 1 uppercase character"
        );
      }

      if (
        document.getElementById("passwordAgainInput").value ==
          document.getElementById("createPasswordInput").value &&
        document.getElementById("passwordAgainInput").value.length != 0
      ) {
        createPasswordAgainBool = true;
        document.getElementById("passwordAgainInput").style.border =
          "medium solid #3f9140";
      } else {
        createPasswordAgainBool = false;
        document.getElementById("passwordAgainInput").style.border =
          "medium solid #c9021d";
      }

      if (
        validatePhoneNum(document.getElementById("createPhoneNumInput").value)
      ) {
        createPhoneNumBool = true;
        document.getElementById("createPhoneNumInput").style.border =
          "medium solid #3f9140";
      } else {
        createPhoneNumBool = false;
        document.getElementById("createPhoneNumInput").style.border =
          "medium solid #c9021d";
      }

      if (validateSelectedPhoneNum(this.selectedNum)) {
        createPhoneNumSelectBool = true;
        document.getElementById("createPhoneNumSelectInput").style.border =
          "medium solid #3f9140";
      } else {
        createPhoneNumSelectBool = false;
        document.getElementById("createPhoneNumSelectInput").style.border =
          "medium solid #c9021d";
      }

      if (this.selectedOrganization) {
        createOrgBool = true;
        document.getElementById("organizationInput").style.border =
          "medium solid #3f9140";
      } else {
        createOrgBool = false;
        document.getElementById("organizationInput").style.border =
          "medium solid #c9021d";
      }

      if (document.getElementById("termsConditions").checked) {
        termsConditionsBool = true;
        document.getElementById("termsConditions").style.border =
          "medium solid #3f9140";
      } else {
        termsConditionsBool = false;
        document.getElementById("termsConditions").style.border =
          "medium solid #c9021d";
      }
      if (
        firstNameBool &&
        lastNameBool &&
        createEmailBool &&
        createPasswordBool &&
        createPasswordAgainBool &&
        createPhoneNumBool &&
        createPhoneNumSelectBool &&
        createOrgBool &&
        termsConditionsBool
      ) {
        let msg = await dp.addUser(
          this.createPassword,
          this.createEmail,
          this.plusPhone + this.selectedNum + this.createPhoneNum,
          this.role,
          this.createLastName,
          this.createFirstName,
          this.createOrganization || this.selectedOrganization
        );
        document.getElementById("closeModal").click();
        alert(msg.message);
      } else {
        alert("Drone creation failed! Please fill all the fields!");
      }
    },
    //-------------------------------------------LOGIN------------------------------------------
    async login() {
      const loginStatus = await dp.login(this.useremail, this.password);
      if (loginStatus.message == "Successfull login!") {
        const user = await dp.getUserByEmail(this.useremail);
        localStorage.setItem("username", user.first_name);
        localStorage.setItem("JWT", loginStatus.jwt);
        App.user = localStorage.getItem("username");
        localStorage.setItem("userEmail", this.useremail);
        this.$router.push("/");
      } else {
        if (loginStatus.message.includes("500")) {
          alert("Something went wrong");
        } else if (loginStatus.message === "Wrong Username or password.") {
          alert("Wrong E-mail address or password");
        }
      }
    },

    isEnter() {
      if (event.keyCode == 13) {
        document.getElementById("loginButton").click();
      }
    },
    atKeyDown(event) {
      if (event.keyCode == 13) {
        document.getElementById("sureUserCreateLogin").click();
      }
    },
    atKeyup(event) {
      console.log(event);
      if (
        document.getElementById("firstNameInput").value.length >= 1 ||
        document.getElementById("lastNameInput").value.length >= 1
      ) {
        let string = document.getElementById("firstNameInput").value;
        string = string.charAt(0).toUpperCase() + string.slice(1);
        document.getElementById("firstNameInput").value = string;

        let string2 = document.getElementById("lastNameInput").value;
        string2 = string2.charAt(0).toUpperCase() + string2.slice(1);
        document.getElementById("lastNameInput").value = string2;
      }
    },

    showPassword(key) {
      switch (key) {
        case "loginPassword":
          if (document.getElementById("passwordInput").type == "password") {
            document.getElementById("passwordInput").type = "text";
            document.getElementById("eyeIconLogin").style.display = "none";
            document.getElementById("slashedEyeLogin").style.display = "block";
          } else if (document.getElementById("passwordInput").type == "text") {
            document.getElementById("passwordInput").type = "password";
            document.getElementById("eyeIconLogin").style.display = "block";
            document.getElementById("slashedEyeLogin").style.display = "none";
          }
          break;
        case "password":
          if (
            document.getElementById("createPasswordInput").type == "password"
          ) {
            document.getElementById("createPasswordInput").type = "text";
            document.getElementById("pwEyeLogin").style.display = "none";
            document.getElementById("pwSlashedEyeLogin").style.display =
              "block";
          } else if (
            document.getElementById("createPasswordInput").type == "text"
          ) {
            document.getElementById("createPasswordInput").type = "password";
            document.getElementById("pwEyeLogin").style.display = "block";
            document.getElementById("pwSlashedEyeLogin").style.display = "none";
          }
          break;
        case "passwordAgain":
          if (
            document.getElementById("passwordAgainInput").type == "password"
          ) {
            document.getElementById("passwordAgainInput").type = "text";
            document.getElementById("pwAgainEyeLogin").style.display = "none";
            document.getElementById("pwAgainSlashedEyeLogin").style.display =
              "block";
          } else if (
            document.getElementById("passwordAgainInput").type == "text"
          ) {
            document.getElementById("passwordAgainInput").type = "password";
            document.getElementById("pwAgainEyeLogin").style.display = "block";
            document.getElementById("pwAgainSlashedEyeLogin").style.display =
              "none";
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style>
.largeModal{
  min-width: 90%
}
.modalFooter{
  max-height: 70px
}
#createPhoneNumSelectInput{
  max-width: 55px
}
#createPhoneInput{
  width: 55px; 
  background-color: #343a40
}
#sendPwRequestButton {
  float: right;
  border-radius: 15px;
  background-color: rgb(64, 76, 43);
  border-color: rgb(64, 76, 43);
  font-family: "Raleway", sans-serif;
}
#pwEyeLogin {
  bottom: 0px;
}
#pwSlashedEyeLogin {
  display: none;
  bottom: 0px;
}
#pwAgainEyeLogin {
  bottom: 0px;

  position: relative;
}
#pwAgainSlashedEyeLogin {
  display: none;
  bottom: 0px;

  position: relative;
}
#eyeIconLogin {
  bottom: 0px;
  position: relative;
}

#slashedEyeLogin {
  display: none;
  bottom: 0px;
  position: relative;
}

#showPasswordButtonLogin {
  position: absolute;
  left: 370px;
  bottom: 215px;
  color: #fff;
}
#showPasswordAgainButtonLogin {
  position: absolute;
  left: 370px;
  bottom: 160px;
  color: #fff;
}
.eyeButtonLogin {
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  color: black;
  position: relative;
  bottom: 28px;
  float: right;
  z-index: 2;
  margin: 2px;
  min-height: 24px;
  min-width: 20px;
}

.logoPlaceBackground {
  width: 200px;
  height: 120px;
  position: fixed;
  float: left;
  top: 0px;
  border-radius: 15px;
}
.logoPlace1 {
  float: left;
  bottom: 100px;
  position: fixed;
}
.logoPlace2 {
  float: left;
  bottom: 60px;
  left: 400px;
  position: fixed;
}
.backgroundColor {
  background-color: rgb(33, 37, 41, 0.45);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  position: absolute;
}

[list]::-webkit-calendar-picker-indicator {
  display: none !important;
}
.dropdown-toggle::after {
  float: right;
  margin-top: 10px;
}
.phoneNumDropdownMenu {
  width: 15%;
  max-width: 15%;
  min-width: 15%;
  max-height: 150px;
  overflow-y: auto;
}
.phoneNumDropdownItem {
  padding-left: 10px;
}
.org-dropdown-menu {
  max-height: 150px;
  overflow-y: auto;
  width: 50%;
}
/* ----------SCROLLBAR-----------*/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6d6875;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b5838d;
}

.my-custom-scrollbar {
  position: relative;
  overflow: auto;
}

/* SCROLLBAR END */

.techDropdownLogin {
  font-size: 16px;
  max-height: 50px;
  min-height: 45px;
  height: 2.8vw;
  background-color: #343a40;
  color: #ffffff2b;
  width: 54%;
  float: right;
  border-radius: 15px;
  border: 0;
}
.labelText {
  top: 10px;
  position: relative;
}
.phoneInputField {
  background-color: #343a40;
  color: #ffffff32;
  max-height: 50px;
  min-height: 45px;
  height: 2.8vw;
  border-radius: 15px;
  border: 0;
}
.phoneInputField:focus  {
  background: #343a40;
}
#createPhoneNumInput {
  width: 90px;
}
#phoneNumDropdown {
  height: 30px;
  font-size: 12px;
}
#termsButton2 {
  background-color: transparent;
  border-color: transparent;
  color: #fff;
  text-decoration: underline;
  padding: 0px;
}
.loginPage {
  font-family: "Raleway", sans-serif;
  background-color: #20262e;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  overflow: hidden;
  z-index: 0;
}
.logo1 {
  width: 200px;
  top: -40px;
  position: absolute;
}

.btn-close-white {
  color: #fff;
  opacity: 1;
}
.backgroundImage {
  background-image: url("../assets/loginbackground.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  position: absolute;
}

#eyeIcon {
  bottom: 0px;
  position: relative;
}

#slashedEye {
  display: none;
  bottom: 0px;
  position: relative;
}

.eyeButton {
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  color: #fff;
  position: relative;
  bottom: 34px;
  right: 40px;
  float: right;
  z-index: 2;
  width: auto;
}
.error {
  top: 10px;
  margin: auto;
  font-size: 1rem;
  position: relative;
  text-align: center;
  color: rgb(255, 75, 75);
}

#newUserButton {
  text-decoration: underline;
  cursor: pointer;
  margin-top: -20px;
  color: #fff;
  width: 100%;
}
#forgotPwButton {
  text-decoration: underline;
  cursor: pointer;
  margin-top: 0px;
  color: #fff;
  width: 100%;
}

.modal-content {
  background-color: #212529;
  color: #fff;
}
.termsModalContent {
  background-color: #212529;
  color: #fff;
}

.login {
  background: #212529;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.47);
  border-radius: 15px;
  top: 25vh;
  color: white;
  margin: auto;
  position: relative;
  z-index: 2;
  max-width: 350px;
  max-height: 230px;
  height: 230px;
}
.loginTitle {
  font-size: 64px;
  color: #fff;
  position: relative;
  top: 25vh;
  margin: auto;
  text-align: center;
  font-weight: 800;
  font-style: bold;
}
.loginContent {
  padding-top: 4vh;
}
#loginButton {
  margin: auto;
  position: relative;
  background-color: #fff;
  border-radius: 16px;
  color: #212529;
  bottom: 3.5vh;
  opacity: 10;
  height: 45px;
  max-width: 100px;
  max-height: 50px;
  min-height: 45px;
  font-size: 1rem;
  font-weight: bold;
  display: block;
  border: 0;
  width: 120px;
  font-weight: 800;
}
#loginButton:hover {
  background-color: #343a40;
  border-color: #343a40;
  color: #fff;
}
.inputBoxLogin {
  max-height: 50px;
  min-height: 45px;
  position: relative;
  margin: auto;
  min-width: 280px;
  font-size: 1rem;
  border-radius: 15px;
  border: 0;
  display: block;
  background: #343a40;
  color: #fff;
}
.inputBox2 {
  background: #343a40;
  border: 0;
  max-height: 50px;
  min-height: 45px;
  position: relative;
  margin: auto;
  min-width: 280px;
  font-size: 1rem;
  border-radius: 15px;
  display: block;
  width: 50%;
  color: #fff;
}
.inputBox2:focus {
  background: #343a40;
}
/* #loginButton {
  font-family: "Raleway", sans-serif;
  height: 45px;
  margin: auto;
  position: relative;
  background-color: #fff;
  border-radius: 16px;
  color: #000;
  bottom: 2vh;
  opacity: 10;
  width: 120px;
  font-size: 1rem;
  display: block;
  border: 0;
  font-weight: bold;
}
#loginButton:hover {
  opacity: 0.8;
} */
#createUserButton {
  margin: auto;
  top: 5px;
  position: relative;
  background-color: #fff;
  border-radius: 16px;
  color: #000;
  bottom: 2vh;
  opacity: 10;
  width: 170px;
  font-size: 1rem;
  display: block;
  border: 0;
  height: 37px;
}
#createUserButton:hover {
  opacity: 0.8;
}

button#sureUserCreateLogin {
  background-color: rgb(64, 76, 43);
  border-color: rgb(64, 76, 43);
  font-family: "Raleway", sans-serif;
  margin-right: 5px;
  border-radius: 16px;
  height: 2vw;
  max-height: 50px;
  min-height: 45px;
  font-size: 1rem;
  font-weight: bold;
}
button#sureUserCreateLogin:hover {
  background-color: rgb(99, 116, 69);
  color: #fff;
  border-color: rgb(99, 116, 69);
}
.new-user-input {
  float: right;
}

#cancelButtonLogin {
  font-family: "Raleway", sans-serif;
  margin-right: 5px;
  border-radius: 16px;
  height: 2vw;
  max-height: 50px;
  min-height: 45px;
  font-size: 1rem;
  font-weight: bold;
}
@media (max-width: 450px) {
  .new-user-input {
    font-size: 12px;
  }

  #createUserModal {
    font-size: 12px;
  }
  #cancelButtonLogin {
    font-size: 12px;
  }
  #sureUserCreateLogin {
    font-size: 12px;
  }
  .loginTitle {
    font-size: 36px;
    top: 100px;
  }
  #showPasswordButtonLogin {
    bottom: 228px;
    left: 350px;
  }
  #showPasswordAgainButtonLogin {
    bottom: 185px;
    left: 350px;
  }
  .checkUser {
    height: 30px;
    margin-top: 30px;
  }
}
</style>
